<template>
  <div class="uid__wrap">
    <PlanIcon
      v-if="['business', 'black', 'io'].includes(plan?.toLowerCase())"
      :plan="plan"
    />
    <AppText
      v-if="uid"
      class="uid"
      :line-height="1.35"
      opacity="0.5"
    >
      {{ uid }}
    </AppText>
  </div>
</template>

<script>
import { defineComponent } from 'vue';

import PlanIcon from '@/components/Icon/PlanIcon.vue';

export default defineComponent({
  components: {
    PlanIcon,
  },
  props: {
    uid: {
      type: String,
      default: '',
    },
    plan: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {};
  },
});
</script>

<style scoped lang="scss">
.uid__wrap {
  display: flex;
  align-items: center;

  .uid {
    padding-top: 1px;
    letter-spacing: 0.2em;
    @include font-medium;
  }

  :deep(.icon) {
    margin-right: 10px;
  }
}
</style>
