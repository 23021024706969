<template>
  <FInput
    v-model="data"
    v-bind="$attrs"
    class="base-amount"
    :is-inherit-attrs="false"
    :validation-error="error"
    size="big-medium"
    tabindex="0"
    :placeholder="placeholder"
    :mask="mask"
    :maxlength="maxLength"
    inputmode="decimal"
  >
    <template #suffix>
      <div class="currency d-flex align-items-center">
        <CountryFlag :currency="amountCurrency" size="18px" style="margin-right: 9px;" />
        <AppText class="font-medium" size="12px" color="var(--color-black)">
          {{ amountCurrency }}
        </AppText>
      </div>
    </template>
  </FInput>
</template>

<script setup>
import { computed } from 'vue';

import CountryFlag from '@/components/Icon/CountryFlag.vue';

import { currency as currencyFromStore } from '@/composables/useCurrency';
import { getMask } from '@/utils/numberFormatter';

const props = defineProps({
  error: {
    type: Object,
    default: () => {},
  },
  placeholder: {
    type: [String, Number],
    default: '100',
  },
  currency: {
    type: String,
    default: '',
  },
  numberFormat: {
    type: String,
    default: '',
  },
});

const data = defineModel({ type: String, default: '' });
const amountCurrency = computed(() => props.currency || currencyFromStore.value?.value);

const mask = computed(() => getMask(amountCurrency.value, props.numberFormat));

const maxLength = computed(() => {
  if (props.numberFormat !== '') {
    return 11;
  }

  return 9;
});
</script>

<style scoped lang="scss">
:deep(.base-amount) {
  .el-input__inner {
    font-size: 27px !important;
  }
}

.currency {
  background: var(--color-black-005);
  border-radius: 54px;
  padding: 8px 12px;
  cursor: default;
}
</style>
