import { useFetch } from '@/api';
import { processIncomingWalletData, postProcessWallets } from '@/composables/useWallet';
import { useStore } from '@/store';
import { i18n } from '@/plugins/localization';
import { useNotifications } from '@/composables/useNotifications';

export const walletConnect = async (params) => {
  const { axiosWrapper } = useFetch();
  const { isSuccess, error } = await axiosWrapper({
    type: 'put',
    url: '/settings/merchant/non-custodial/wallet',
    params,
    hasForceNotification: true,
  });

  return { isSuccess, error };
};

export const getWalletData = async ({ isFreshResponse } = { isFreshResponse: false }) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess, error } = await axiosWrapper({
    type: 'get',
    url: '/settings/merchant/public-address',
    params: { isFreshResponse },
  });

  if (isSuccess) {
    const nonCustodialWallets = processIncomingWalletData(data.nonCustodialWallets);
    const processedData = { ...data, nonCustodialWallets };

    const store = useStore();
    store.$patch({ walletData: processedData });
    store.$patch({ rawWallets: data.nonCustodialWallets });

    return { isSuccess, data: processedData };
  }

  return { isSuccess, error };
};

export const getGlobalCoinsInfo = async ({ isFreshResponse } = { isFreshResponse: false }) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: '/settings/general/coins/availability',
    params: { isFreshResponse },
  });

  return { data, isSuccess };
};

export const updatePublicPayments = async (payme) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess, error } = await axiosWrapper({
    type: 'put',
    url: '/settings/merchant/public-payments',
    params: {
      amount: Number(payme.amount),
      isEnabled: payme.isEnabled,
      incognito: payme.incognito,
    },
  });

  if (!isSuccess && error.data?.code === 400) {
    const { addNotification } = useNotifications();
    const { t } = i18n.global;
    addNotification({ text: t(`errors.${error.data?.message}`), config: { color: 'error' } });
  }

  return { isSuccess, data };
};

export const fetchWalletData = async ({ isFreshResponse } = { isFreshResponse: false }) => {
  const res = await Promise.all([
    getGlobalCoinsInfo({ isFreshResponse }),
    getWalletData({ isFreshResponse }),
  ]);

  const walletsAvailability = res[0].data;
  const rawWallets = res[1].data?.nonCustodialWallets;

  const nonCustodialWallets = postProcessWallets({ wallets: rawWallets, walletsAvailability });
  const publicPayments = res[1].data?.publicPayments;

  return {
    nonCustodialWallets,
    publicPayments,
  };
};

export const coinStatusUpdate = async (coin) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'patch',
    url: `/wallet/non-custodial/status/${coin.toUpperCase()}`,
  });

  const walletsAvailability = await getGlobalCoinsInfo().then((res) => res.data);

  if (isSuccess) {
    const nonCustodialWallets = processIncomingWalletData(data.wallet);

    const processedData = postProcessWallets({ wallets: nonCustodialWallets, walletsAvailability });
    const store = useStore();

    store.$patch({
      walletData: { nonCustodialWallets },
      processedWallets: { nonCustodialWallets: processedData },
    });
  }

  return { isSuccess, data };
};

export const thirdPartyTokensBulkUpdate = async (isEnabled) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'patch',
    url: 'wallet/non-custodial/status/coin/third-party',
    params: { isEnabled },
  });

  const walletsAvailability = await getGlobalCoinsInfo().then((res) => res.data);

  if (isSuccess) {
    const nonCustodialWallets = processIncomingWalletData(data.wallet);

    const processedData = postProcessWallets({ wallets: nonCustodialWallets, walletsAvailability });
    const store = useStore();

    store.$patch({
      walletData: { nonCustodialWallets },
      processedWallets: { nonCustodialWallets: processedData },
    });
  }

  return { isSuccess, data };
};
