<template>
  <div class="plan-icon-wrap">
    <div v-if="isBorder" class="border" />
    <AppIcon :name="iconName" size="25px" is-img-tag />
  </div>
</template>

<script>
import { computed } from 'vue';

export default {
  name: 'PlanIcon',
  props: {
    plan: {
      type: String,
      default: '',
    },
    isBorder: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const planName = computed(() => props.plan.toLowerCase());

    const iconName = computed(() => {
      if (planName.value === 'business') {
        return 'plan-business';
      }
      if (planName.value === 'black') {
        return 'plan-black';
      }
      if (planName.value === 'io') {
        return 'plan-io';
      }
      return 'user';
    });

    return {
      iconName,
    };
  },
};
</script>

<style lang="scss" scoped>
.plan-icon-wrap {
  position: relative;

  .border {
    position: absolute;
    width: 29px;
    height: 19px;
    border: 2px solid var(--color-white);
    border-radius: 13px;
    left: 2;
    top: calc(50% - 9.5px);
    z-index: -1;
  }
}
</style>
```
