<template>
  <div class="account__error">
    <AppText size="16px" :line-height="1.35">
      {{ t('pay.label.unknownAccount') }}
    </AppText>
    <FButton is-full-width to="/">
      {{ t('pay.button.returnHome') }}
    </FButton>
  </div>
</template>

<script>
export default {
  name: 'AccountError',
};
</script>

<style lang="scss" scoped>
.account__error {
  padding: 30px 40px 36px;
  height: 390px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  @include mq('mobile') {
    padding: 30px 40px 100px;
  }
}
</style>
