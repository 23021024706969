import { useFetch } from '@/api';
import { useNotifications } from '@/composables/useNotifications';

export const getMerchantByUid = async (uid) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: `/merchants/uid/${uid}`,
    isErrorNotification: false,
    params: { isFreshResponse: true },
  });

  const isPaymeEnabled = data?.publicPayments?.isEnabled;
  return { data, isSuccess, isPaymeEnabled };
};

export const createPayment = async ({
  memo, clientEmail, baseAmount, uid, captchaToken,
}) => {
  const { axiosWrapper } = useFetch();
  const {
    data, isLoading, isSuccess, error, errorObject,
  } = await axiosWrapper({
    type: 'post',
    url: '/merchants/payments/public-payment',
    params: {
      memo,
      clientEmail,
      baseAmount,
      uid,
      captchaToken,
    },
  });

  const uuid = data?.uuid;

  if (!isSuccess) {
    const { addNotification, t } = useNotifications();

    if (error.data.code === 429 && error.data.message === 'REQUEST_BLOCKED') {
      addNotification({
        text: t('pay.notification.requestsBlockedSystemError'),
        config: { color: 'warning', duration: 5000 },
      });
    } else if (error.data.code === 405 && error.data.message === 'MERCHANT_PAYMENT_QUOTA_EXHAUSTED') {
      addNotification({
        text: t('pay.notification.paymentQuotaExhaustedSystemError'),
        config: { color: 'warning', duration: 5000 },
      });
    } else if (error.data.code !== 400) {
      addNotification({
        text: t('pay.notification.createPaymentContactMerchant'),
        config: { color: 'warning', duration: 5000 },
      });
    }
  }

  return {
    isSuccess, isLoading, uuid, error, errorObject,
  };
};
