<template>
  <div :class="{ hide: isBodyHide }">
    <TransitionGroup name="show">
      <AccountError v-if="isAccountError" />
      <PayMeCard
        v-if="!isAccountError && !isLoading"
        :company-data="companyData"
        :user-id="userId"
        :is-incognito="isIncognito"
        :captcha-token="captchaToken"
        @check-status="checkStatus"
        @success="onSuccess"
        @error="onError"
      />

      <AppCaptcha
        v-if="isCaptchaVisible"
        class="captcha"
        @captcha-solved="onCaptchaSolved"
      />
    </TransitionGroup>
  </div>
</template>

<script>
import { onBeforeMount, ref } from 'vue';

import AppCaptcha from '@/components/Modules/AppCaptcha.vue';

import { router, useRoute } from '@/router';
import { paymentState } from '@/layouts/PaymentLayout/paymentState';
import { getPaymentById, getAvailableWallets } from '@/views/client/ClientPayment/api';
import { getGlobalCoinsInfo } from '@/api/merchantWallet';

import { getMerchantByUid } from './api';
import AccountError from './AccountError.vue';
import PayMeCard from './PayMeCard.vue';
import { isButtonLoading } from './state';

export default {
  name: 'ClientPay',
  components: {
    AccountError,
    PayMeCard,
    AppCaptcha,
  },
  emits: ['load'],
  setup(props, { emit }) {
    const route = useRoute();
    const userId = route.params?.uid;

    const companyData = ref({});

    const isLoading = ref(true);
    const isIncognito = ref(false);

    onBeforeMount(async () => {
      if (userId) {
        checkStatus();
      } else {
        processError();
      }
      emit('load');
    });

    const {
      setAvatar, processError, isAccountError, isCaptchaVisible, isLoaderShow, setMerchantPlan,
    } = paymentState();
    async function checkStatus() {
      const { isPaymeEnabled, data } = await getMerchantByUid(userId);
      isLoading.value = false;
      if (isPaymeEnabled) {
        companyData.value = data;
        isIncognito.value = data.publicPayments.incognito;

        setAvatar(data.profilePicture?.crop);
        setMerchantPlan(data.subscriptionPlan);
      } else {
        processError();
      }
    }

    const onSuccess = async (uuid) => {
      const { data, isSuccess } = await getPaymentById(uuid);
      const merchantId = data.merchantId;

      if (isSuccess) {
        try {
          await Promise.all([getGlobalCoinsInfo(), getAvailableWallets(merchantId)]);

          router.push(`/payment/${uuid}`);
        } catch (e) {
          isButtonLoading.value = false;
        }
      }
    };

    const isBodyHide = ref(false);
    const captchaToken = ref('');

    const onCaptchaSolved = (token) => {
      setTimeout(() => {
        isBodyHide.value = true;
        isCaptchaVisible.value = false;
        isLoaderShow.value = true;
        captchaToken.value = token;
      }, 512);
    };

    const onError = () => {
      isCaptchaVisible.value = false;
      isBodyHide.value = false;
      isLoaderShow.value = false;
    };

    return {
      isLoaderShow,
      isCaptchaVisible,
      isBodyHide,
      captchaToken,

      companyData,
      userId,

      isLoading,
      isIncognito,
      isAccountError,

      onSuccess,
      onError,
      onCaptchaSolved,
      checkStatus,
    };
  },
};
</script>

<style lang="scss" scoped>
.hide {
  display: none;
}
</style>
