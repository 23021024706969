<template>
  <div class="captcha">
    <AppText
      variant="div"
      size="16px"
      class="font-medium "
    >
      Solve Captcha
    </AppText>

    <VueHcaptcha
      :sitekey="siteKey"
      @verify="onSolved"
      @rendered="onRendered"
    />
  </div>
</template>

<script>
import VueHcaptcha from '@hcaptcha/vue3-hcaptcha';

import { paymentState } from '@/layouts/PaymentLayout/paymentState';

export default {
  name: 'AppCaptcha',
  components: {
    VueHcaptcha,
  },
  emits: ['captchaSolved'],
  setup(props, { emit }) {
    const siteKey = process.env.VUE_APP_HCAPTCHA_SITE_KEY;

    const onSolved = (token) => {
      emit('captchaSolved', token);
    };

    const { isLoaderShow } = paymentState();

    const onRendered = () => {
      setTimeout(() => {
        isLoaderShow.value = false;
      }, 1000);
    };

    return { siteKey, onSolved, onRendered };
  },
};
</script>

<style scoped lang="scss">
.captcha {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
</style>
