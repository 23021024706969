import { computed, ref } from 'vue';

const avatar = ref(null);
const icon = ref(null);
const isIcon = computed(() => !avatar.value && icon.value);
const isAvatarEnabled = ref(true);

const isAvatarVisible = computed(() => (avatar.value || icon.value) && isAvatarEnabled.value);

const isAccountError = ref(false);
const isCaptchaVisible = ref(false);
const isLoaderShow = ref(false);
const paymentStatus = ref('');

const merchantPlan = ref(null);

export const paymentState = () => {
  const setAvatar = (value) => {
    isAvatarEnabled.value = true;
    if (value) {
      avatar.value = value;
    } else {
      icon.value = 'user-default';
    }
  };

  const setMerchantPlan = (value) => {
    merchantPlan.value = value;
  };

  const processError = () => {
    isAvatarEnabled.value = true;
    isAccountError.value = true;
    icon.value = 'ban-block-slash';
  };

  const disableAvatar = () => {
    isAvatarEnabled.value = false;
  };

  return {
    avatar,
    icon,
    isIcon,

    isAccountError,
    isAvatarVisible,

    isCaptchaVisible,
    isLoaderShow,
    paymentStatus,

    setAvatar,
    processError,
    disableAvatar,

    merchantPlan,
    setMerchantPlan,
  };
};
