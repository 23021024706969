<template>
  <div class="body">
    <UidPlan
      :plan="companyData.subscriptionPlan"
      :uid="companyData.uid"
    />
    <AppText
      variant="div"
      class="font-medium comp__name"
      size="16px"
      :line-height="1.5"
    >
      {{ t('pay.label.makePayment') }} {{ companyData.companyName }}
    </AppText>

    <ActionForm
      :request-fields="requestFields"
      :validation-schema="validationSchema"
      :response-errors="fieldsErrors"
      @validate="goToNextCard"
    >
      <template #default="{ errors, action }">
        <AmountInput
          v-model="requestFields.baseAmount"
          :error="errors.baseAmount"
          :placeholder="amountPlaceholder"
          :currency="companyData.baseCurrency"
          :number-format="companyData.numberFormat"
        />
        <template v-if="!isIncognito">
          <FInput
            v-model="requestFields.clientEmail"
            :validation-error="errors.clientEmail"
            :placeholder="t('pay.label.emailPlaceholder')"
            size="medium"
            class="email-field"
          />
        </template>

        <FInput
          v-model="requestFields.memo"
          :validation-error="errors.memo"
          :placeholder="t('pay.label.memoPlaceholder')"
          type="textarea"
          minlength="10"
          maxlength="100"
          @keypress.enter.stop="e => onPressEnter(e, action)"
        />

        <div v-if="isIncognito" class="incognito">
          <div class="pic">
            <AppIcon
              name="incognito"
              size="18px"
              opacity="0.4"
              is-img-tag
            />
          </div>
          <AppText opacity="0.4">
            {{ t('pay.label.incognito') }}
          </AppText>
        </div>
      </template>
      <template #action>
        <FButton is-full-width :loading="isButtonLoading">
          {{ t('common.continue') }}
        </FButton>
      </template>
    </ActionForm>
  </div>
</template>

<script>
import { computed, watch } from 'vue';
import dayjs from 'dayjs';

import AmountInput from '@/components/Payment/AmountInput.vue';
import UidPlan from '@/components/UidPlan.vue';

import { addThousandsSeparators, parseSeparatedNumber } from '@/utils/numberFormatter';
import { paymentState } from '@/layouts/PaymentLayout/paymentState';
import { useLang } from '@/composables/useLang';
import { useNotifications } from '@/composables/useNotifications';
import { ActionForm, useValidation, yup } from '@/validation';
import { baseAmountRule, emailRule, memoRule } from '@/validation/rules';

import { createPayment } from './api';
import { isButtonLoading } from './state';

export default {
  name: 'PayMeCard',
  components: {
    UidPlan,
    ActionForm,
    AmountInput,
  },
  props: {
    companyData: {
      type: Object,
      default: () => {},
    },
    userId: {
      type: String,
      default: '',
    },
    isIncognito: {
      type: Boolean,
      default: false,
    },
    captchaToken: {
      type: String,
      default: '',
    },
  },
  emits: ['success', 'error', 'check-status'],
  setup(props, { emit }) {
    const minAmount = computed(() => props.companyData?.publicPayments?.amount);

    const amountPlaceholder = computed(() => {
      const res = addThousandsSeparators({
        val: minAmount.value,
        separator: props.companyData.numberFormat,
      });
      return res?.toString();
    });

    const { initState, fieldsErrors } = useValidation();

    const fields = {
      baseAmount: null,
      clientEmail: '',
      memo: '',
      isIncognito: props.isIncognito,
    };

    const validationSchema = yup.object().shape({
      baseAmount: baseAmountRule(minAmount.value, props.companyData.numberFormat, props.companyData.baseCurrency),
      clientEmail: yup
        .string()
        .when('isIncognito', {
          is: false,
          then: () => emailRule,
        }),
      memo: yup
        .string()
        .when('isIncognito', {
          is: true,
          then: () => memoRule({ min: 10, max: 100 }),
        }),
    });

    const { requestFields } = initState(fields);

    const isAntiSpamBlocked = computed(() => dayjs(props.companyData?.antiSpamTimer || dayjs()).diff(dayjs(), 'second') > 0);

    const { isCaptchaVisible, isLoaderShow } = paymentState();

    const goToNextCard = async () => {
      isButtonLoading.value = true;
      if (isAntiSpamBlocked.value) {
        const { addNotification, t } = useNotifications();
        addNotification({
          text: t('pay.notification.requestsBlockedSystemError'),
          config: { color: 'warning', duration: 5000 },
        });

        emit('check-status');

        return;
      }

      if (props.companyData.captcha) {
        isLoaderShow.value = true;
        isCaptchaVisible.value = true;
      } else {
        await onCreatePayment();
      }
    };

    const onCreatePayment = async () => {
      const { isSuccess, uuid, errorObject } = await createPayment({
        memo: requestFields.memo,
        clientEmail: props.isIncognito ? null : requestFields.clientEmail,
        baseAmount: parseSeparatedNumber(requestFields.baseAmount, props.companyData.numberFormat),
        uid: props.userId,
        captchaToken: props.captchaToken,
      });
      if (isSuccess) {
        emit('success', uuid);
      } else {
        fieldsErrors.value = errorObject.fields;
        emit('error');
        isButtonLoading.value = false;
      }
    };

    watch(() => props.captchaToken, async () => {
      if (props.captchaToken.length) {
        isButtonLoading.value = true;
        await onCreatePayment();
      }
    });

    const { language } = useLang();
    watch(language, () => {
      fieldsErrors.value = {};
    });

    const onPressEnter = (event, fn) => {
      event.preventDefault();
      fn();
    };

    return {
      goToNextCard,
      isButtonLoading,
      fieldsErrors,

      amountPlaceholder,
      validationSchema,
      requestFields,

      onPressEnter,
    };
  },
};
</script>

<style lang="scss" scoped>
.pic {
  display: block;
  width: 100%;
}

.body {
  padding: 30px 40px 36px;

  @include mq('mobile') {
    padding: 30px;
    padding-bottom: 105px;
  }

  .comp__name {
    margin: 18px 0 20px;
  }

  .incognito {
    margin-top: 20px;
    display: flex;
    align-items: center;

    .pic {
      background: var(--color-F7F7F7);
      flex: 0 0 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50%;
      margin-right: 18px;
    }
  }

  :deep(.action) {
    float: unset;
  }

  :deep(.f-input) {
    margin-top: 12px;
  }

  :deep(.el-button) {
    margin-top: 20px;
  }
}
</style>
