import { useFetch } from '@/api';
import { fullCoinsArray, coinsSortWeight } from '@/common/data';
import { getGlobalCoinsInfo } from '@/api/merchantWallet';
import { convertNestedArrayToFlatObject } from '@/composables/useWallet';

import { isButtonLoading } from '../ClientPay/state';

export const getPaymentById = async (paymentUUID, isFreshResponse = false) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: `/merchants/payments/public/uuid/${paymentUUID}`,
    params: { isFreshResponse },
    isErrorNotification: false,
  });
  return { data, isSuccess };
};

export const getAvailableWallets = async (uuid, isFreshResponse = true) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: `/wallet/statuses/${uuid}`,
    params: { isFreshResponse },
  });
  return { data, isSuccess };
};

export const linkAccessed = async (uuid) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'post',
    url: `/merchants/payments/${uuid}/link-accessed`,
    isErrorNotification: false,
  });
  isButtonLoading.value = false;
  return { data, isSuccess };
};

export const invoicePayment = async ({ uuid, coin }) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'post',
    url: `/merchants/payments/activate/${uuid}`,
    params: {
      exchangeCurrency: coin,
    },
    hasForceNotification: true,
  });
  return { data, isSuccess };
};

export const reactivatePayment = async ({ uuid, coin }) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'post',
    url: `/merchants/payments/reactivate/${uuid}`,
    params: { exchangeCurrency: coin },
    hasForceNotification: true,
  });
  return { data, isSuccess };
};

export const getConfirmations = async (exchangeCurrency) => {
  const { axiosWrapper } = useFetch();
  const { data, isSuccess } = await axiosWrapper({
    type: 'get',
    url: '/merchants/payments/confirmations',
  });

  const confirmations = data[exchangeCurrency];

  return { data, isSuccess, confirmations };
};

export const getCoinsAvailability = async (merchantId) => {
  const res = await Promise.all([getGlobalCoinsInfo({ isFreshResponse: true }), getAvailableWallets(merchantId, false)]);
  const merchantCoins = convertNestedArrayToFlatObject(res[1].data);

  const coins = fullCoinsArray.map((coin) => {
    const isDisabledByAdmin = !res[0].data[coin.value];
    const enabled = merchantCoins[coin.value];
    return {
      ...coin,
      isDisabledByAdmin,
      enabled,
    };
  })
    .sort((a, b) => coinsSortWeight[a.coin] - coinsSortWeight[b.coin])
    .sort((a, b) => b.enabled - a.enabled);

  return coins;
};
